const sortData = (data: any): any => {
  const nodesDepth1 = data.filter(
    (node: any) => node.depth === 1 || node.depth === 0,
  );
  const nodesDepth2 = data.filter((node: any) => node.depth === 2);
  const nodesDepth3 = data.filter((node: any) => node.depth === 3);

  const nodes = nodesDepth1.sort((a: any, b: any) =>
    a.countPeopleAnswered > b.countPeopleAnswered ? -1 : 1,
  );

  const sorted2 = nodesDepth2.sort((a: any, b: any) =>
    a.countAnswers > b.countAnswers ? -1 : 1,
  );

  if (sorted2.length > 0) {
    const v = sorted2[0].parent;
    const index = nodes.indexOf(v);
    nodes.splice(index + 1, 0, ...sorted2);
  }

  const sorted3 = nodesDepth3.sort((a: any, b: any) =>
    a.countAnswers > b.countAnswers ? -1 : 1,
  );

  if (sorted3.length > 0) {
    const v = sorted3[0].parent;
    const index = nodes.indexOf(v);
    nodes.splice(index + 1, 0, ...sorted3);
  }

  return nodes;
};

export default sortData;
